@import url('https://fonts.googleapis.com/css?family=Literata:600|Montserrat:500&display=swap');

#content {
  text-align: center;
  width: calc(100vw - (100vw - 100%));
  min-height: 100vh;
  font-family: Literata, sans-serif;
  line-height: 1.3;

  color: #ffffffbf;
  background: #36454f;
  
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr 1fr 1fr 1fr auto;
}

.row {
  &:nth-child(odd) {
    background: #1f1b24;
  }

  padding: 30px;
  align-items: center;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 10px;
  grid-template-areas: 'header details details details details';

  header {
    padding-right: 30px;
    font-size: 2em;
    grid-area: header;
    font-weight: 600;
  }

  &:first-of-type {
    header {
      transform: rotate(-7deg);
    }
  }

  .details {
    text-align: left;
    font-family: Montserrat, sans-serif;
    grid-area: details;
    font-weight: 500;

    span {
      color: #ffa600e6;
    }

    .footnote {
      font-size: 0.85em;
      font-style: italic;
    }

    .contact {
      color: #ffa600e6;
      text-decoration: none;
      border-bottom: 1.5px solid #ffa600e6;
      cursor: pointer;
      display: inline-block;

      transition: all .25s;
      &:hover {
        filter: brightness(1.2)
      }
    }
    
  }

}

.footer {
  text-align: right;
  font-size: 0.75em;
  font-family: Montserrat, sans-serif;
  color: #ffffffbb;
  background: black;

  p {
    margin: 4px 8px;
  }
}

@media screen and (max-width: 760px) {

  #content {
    grid-template-rows: auto;
  }

  .row {
    padding: 16px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      'header'
      'details';
  
    header {
      padding-right: 0;
      text-align: center;
    }
  }
}